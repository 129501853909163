import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class TermsPage extends React.Component {
  render() {
    //const enNewsEdges = this.props.data.en.edges
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>Terms and Condition │ Cookin’Burger</title>
          <meta name="description" content="New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!" />
        </Helmet>
        <div className="l-main">
          <div className="l-term-section">
            <div className="section-inner_m">
              <div className="l-term-section-box">
                <div className="l-term-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  Terms and Condition
                </div>
                <p className="l-term-section-cap wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                  <div className="terms-title">1. Objects and Purposes</div>
                  These Terms of Use sets forth the terms and conditions governing the use of online games (the "Games") offered on the PlayMining online gaming platform (including any related media channels, mobile web services or mobile applications, collectively, the "Services"), and access to and use of all services, features and content available in connection with the Services. The operator of the Service and the operator of the Game are collectively referred to as the "Operator".<br />
                  <br />
                  By using the Game, the individual using the Service ("User") agrees to these Terms of Use and to the collection and processing of data as set forth in the Privacy Policy. If the User does not agree to the Terms of Use or the Privacy Policy, the User may not access or use the Game. In addition, in the event that the User accesses or uses the Service, he/she may be subject to additional guidelines or rules applicable to particular parts of the Service as may be modified by the Operator at any time. All such guidelines or rules are hereby incorporated into these Terms of Use.<br />
                  <br />
                  <div className="terms-title">2. Definitions & Interpretation</div>
                  In these Terms of Use, the following words and phrases shall have the meanings set forth below;
                  Account: means the PlayMining ID account opened by the User to use the Service.<br />
                  Game Data: means the history of the User's use of the Game and any information that occurs in the Game (including characters, items acquired, in-game currency obtained, etc.).<br />
                  In-Game Currency: The in-game currency issued and consumed within the Game.<br />
                  NFTs: Non Fungible Tokens generated on the blockchain.<br />
                  PlayMining NFT: the online auction service operated by the Operator.<br />
                  DEAPcoin: A crypto asset that can be used to purchase certain items in the game and to buy and sell NFTs on the PlayMining NFT.<br />
                  PlayMining ID: An ID that is opened separately in a manner prescribed by the Operator.<br />
                  <br />
                  <div className="terms-title">3. Use of the Games</div>
                  In order to use the Games, including saving and continuing to use the game data of the Game, the User must register a PlayMining ID. The User shall register a PlayMining ID by registering the items of registration specified by the Operator in the manner described by the Operator.<br />
                  <br />
                  <div className="terms-title">4. Account Registration</div>
                  Account registration is required to use the Service. The User shall register an Account (including PlayMining ID registration) by registering the registration items specified by the Operator in the manner described by the Operator. The User shall register with the most current and accurate information at the time of registration. In the event that the User fails to provide current and accurate information, the Operator may refuse the registration of the Account.<br /><br />
                  The User represents and warrants that they are of legal age by using the Service. In the event that the User is a minor, he/she shall register after obtaining the consent of a legal representative such as a person with parental authority, and the User represents and warrants that he/she has obtained the consent of the legal representative.
                  In the event that it becomes clear after Account registration that the User does not have the consent of his/her legal representative, the Operator may suspend or terminate the Account, and refuse to provide some or all of the Services now or in the future.<br />
                  <br />
                  In the event there is any change in the registered information after the registration of an Account, the User shall promptly notify the Operator of such change in the registered information. The Operator shall not be responsible or liable for any damage, loss or costs suffered or incurred by the User as a result of the User’s failure to promptly report to the Operator of such changes, except in cases of willful misconduct or gross negligence on the part of the Operator.<br />
                  <br />
                  <div className="terms-title">5. Management of the Account</div>
                  The User shall strictly manage his/her account and shall not use the account in a manner that the Operator deems to be unreasonable. The User shall not disclose the account to a third party in a manner that impairs the security of the account, nor shall the User conduct the following activities including, but not limited to lend, transfer, sell or, pledge the Account to any third party.<br />
                  Use of the Service through the Account shall be deemed to be the act of the User himself/herself as the holder of said Account, and the User shall be responsible for any and all liabilities incurred through the use of said Account.The Operator shall not be liable for any damages incurred by the User, except in the event that the Operator is willful or grossly negligent.<br />
                  <br />
                  <div className="terms-title">6. Restriction, Suspension or Termination of Account</div>
                  The User acknowledges and agrees that the Operator shall be entitled to terminate, suspend or restrict the User’s rights to the Account or access to any part of the Services, at any time, without notice, such as but not limited to, the following situations:<br />
                  When a user purchases an item, the DEAPcoin used for the purchase will be deducted from the balance of the Wallet.<br />
                  1) the User and/or the Account is engaged in any prohibited activities in clause 16 or otherwise acts in violation of these Terms of Use, Privacy Policy or any existing laws, rules and regulations;<br />
                  2) the User and/or the Account is subjected to any governmental proceeding, criminal investigation, litigation, etc.;<br />
                  3) the User's use of the Service is related to illegal, unlawful, or prohibited activities;<br />
                  4) the Account is detected to have been compromised or accessed without being authorized;<br />
                  5) failure by the User to make payments in accordance with these Terms of Use;<br />
                  6) a petition for bankruptcy or civil rehabilitation proceedings is filed against the User;<br />
                  7) the payment is suspended or deemed invalid by the settlement provider;<br />
                  8) any other events which are deemed by the Operator, in its sole and absolute discretion, to be inappropriate for the User to continue using the Services.<br />
                  <br />
                  The Operator shall not be liable for any damages incurred by the User due to the suspension, restriction, or cancellation of the User's Account, except in the event of willful misconduct or gross negligence on the part of the Operator.<br />
                  <br />
                  <div className="terms-title">7. Game Data</div>
                  The User may use the Game Data of the Game only within the scope of the purposes specified by the Operator, and may not use the Game Data for any other purposes (including any usage which is not specified by the Operator but deemed unreasonable in terms of socially accepted ideas by the Operator).<br />
                  <br />
                  The User may not use the Game Data beyond the extent that the Operator deems reasonable, and may not transfer or allow a third party to use the Game Data.<br />
                  <br />
                  The Operator may delete, move, or change Users' Game Data under the following circumstances:<br />
                  1) the game title corresponding to the Game Data is no longer offered;<br />
                  2) the operation of the game title corresponding to the Game Data is transferred to a third party;<br />
                  3) the User violates these Terms of Use, etc. (including cases in which the Operator determines that the content or creation process of the Game Data violates these Terms of Use, etc.);<br />
                  4) the Operator deems it necessary for technical reasons, such as game data exceeding the designated capacity;<br />
                  5) any other circumstances which the Operator deems it necessary to do so.<br />
                  <br />
                  The Operator shall not be obligated to modify Game Data for users.<br />
                  <br />
                  <div className="terms-title">8. Acquiring In-game Currencies</div>
                  The User shall obtain In-Game Currency through in-game actions (e.g., login bonuses, rewards for battle, etc.).
                  The User shall obtain In-Game Currency only in accordance with any restrictions or requirements imposed by the Operator in its sole discretion.<br />
                  <br />
                  <div className="terms-title">9. Use of In-game Currencies</div>
                  The User may use the In-Game Currency to receive the corresponding items or special services as prescribed by the Operator, within the limits of the balance of use.<br />
                  Once the User has received a corresponding item, the In-Game Currency used to receive such corresponding item will be deleted.<br />
                  The balance of the In-Game Currency used will be recorded on the Operator's servers along with the PlayMining ID. The User may use the In-Game Currency of the PlayMining ID as long as the same PlayMining ID is used.<br />
                  The User is solely responsible for the management of the In-Game Currency.<br />
                  The User may not lend, buy, sell, gift, or otherwise transfer the In-Game Currency to any third party.<br />
                  The User shall use the In-Game Currency within the limits set by the Operator in the event that the Operator imposes certain restrictions on the use of the In-Game Currency.<br />
                  <br />
                  <div className="terms-title">10. Termination of In-Game Currency</div>
                  Any In-Game Currency associated with a PlayMining ID recorded on the Operator's servers will cease to exist in the event that the PlayMining ID is deleted for any reason whatsoever.<br />
                  The User shall not be able to make any claim against the Operator for the deletion of such In-Game Currency, and the Operator shall not compensate the User for the deleted In-Game Currency and shall not be liable for any loss thereof. However, this shall not apply to the case in which the In-Game Currency is deleted due to the Operator's willful act or gross negligence.<br />
                  <br />
                  <div className="terms-title">11. Use of DEAPcoin</div>
                  The User may use DEAPcoin to receive some items or special services in a manner determined by the Operator (collectively, the "DEAPcoin Usage Services") within the limit of the balance held by the User.<br />
                  In the event you receive a DEAPcoin Usage Service, the DEAPcoin you used to receive such service will be deducted from your Wallet balance.<br />
                  The balance of DEAPcoin held by the User will be recorded on the Operator's server together with the User's Account. The User may use the DEAPcoin in the Wallet of such Account as long as he/she uses the same Account.<br />
                  The User is solely responsible for the management of his/her DEAPcoin.<br />
                  The Operator will not cancel or withdraw the use of DEAPcoin or exchange DEAPcoin for cash or other virtual currency for any reason.<br />
                  The User may not lend, buy, sell, gift, or otherwise transfer DEAPcoin to any third party on the Game or at PlayMining NFT.<br />
                  The User shall use DEAPcoin within the limits set by the Operator in the event that the Operator imposes certain restrictions on the use of DEAPcoin.<br />
                  <br />
                  <div className="terms-title">12. Scholarship</div>
                  Scholarship is a system of lending and borrowing NFTs among Users (the User who lends NFTs is called "Owner" and the User who borrows NFTs is called "Scholar"). The period of such lending and borrowing is referred to as the "Scholarship Period").<br />
                  During the Scholarship Period, information about the Scholar, in-game items acquired by the Scholar that the Owner and Scholar agree to send to the Owner in advance, and in-game items will be automatically sent to the Owner.<br />
                  During the Scholarship Period, the Owner may not use the corresponding NFT in any trade on the Service (including the listing of such NFT).<br />
                  The Operator is not involved in any way in the implementation of the Scholarship and will not be responsible and will not be held liable in any way.<br />
                  <br />
                  <div className="terms-title">13. Fees</div>
                  Unless otherwise specified, the use of the Game is provided free of charge for Users, but Users may purchase in-game items through payment methods provided by DEAPcoin or payment agents in partnership with the Operator.<br />
                  TThe User agrees that the Operator shall not be responsible for any connection of the Game with any hardware, software, telecommunications, etc. The User agrees that the Operator shall be responsible for the telecommunication facilities and telecommunication charges, etc. required for the use of the Game.<br />
                  <br />
                  <div className="terms-title">14. Ownership of Intellectual Property Rights</div>
                  All intellectual property rights pertaining to the Services (including or related to all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned, controlled by or licensed to the Operator. The User shall have no intellectual property rights in or to the Game, except for the right to use them in accordance with these Terms of Use.<br />
                  The User may not use the contents of the Game or the website that provides the Game, including but not limited to images, videos, audio, logos, text, and programs, in any manner including reproduction, transfer, loan, translation, modification, reprint, reuse, or copyrighted use to any third party without the Operator's separate written consent.<br />
                  <br />
                  The Company may suspend the provision of the Game temporarily or for a long period of time in the event of any of the following events. In this case, the Company will announce or notify the Users of the suspension in advance by a method that the Company deems appropriate, except when there is an urgent need to do so.<br />
                  <br />
                  <div className="terms-title">15. Handling of Personal Information</div>
                  The Operator will properly handle personal information obtained from Users in connection with the provision of the Service in accordance with the Operator's Privacy Policy, and will strive to appropriately protect and manage personal information.The User shall not use any personal information obtained through deception or other wrongful means on the Service.<br />
                  <br />
                  <div className="terms-title">16. Prohibited Activities</div>
                  The Operator prohibits Users from doing the following in connection with the use of the Games:<br />
                  1) Engaging in defamatory, threatening, make unreasonable demands of or engage in behavior or actions which cause discomfort to others including but not limited to sexual, racist, antisocial behavior, etc.<br />
                  2) Behavior which disrupts the Operator’s operations of the Game or business operations including but not limited to excessive contact, defamation, threats and or unreasonable demands, etc.<br />
                  3) False representation as an Operator representative, other User, or other third party with the intent to impersonate and or deceive others.<br />
                  4) Attempt to defame a third party, etc., and or any other actions attempting to defame or damage a third party.<br />
                  5) Engaging in advertising, trade, exchange of goods, religious activities and or solicitation unrelated to the Game.<br />
                  6) Criminal and or illegal conduct, including any conduct which is illegal under the laws of this or any other country.<br />
                  7) Behavior which goes against general public order and decency.<br />
                  8) Discriminatory or prejudice behaviors against religion, race, sex, ethnicity and or human rights, etc.<br />
                  9) Actions which are inductive of illegal substances and or the furnishing of information thereof.<br />
                  10) Behavior, actions, or the offering of information inductive of suicide or self-harm.<br />
                  11) Sharing of information and or the dissemination of lewd, violent, criminal information including child pornography and or abuse of children.<br />
                  12) Recommending or providing reference to illegal or harmful activities or substances.<br />
                  13) Actions which infringe on, or cause damage to, the sound upbringing of underage persons.<br />
                  14) Collecting, storing, or disclosing the personal information of other Users or other third parties.<br />
                  15) Using the Service for purposes other than the original purpose, such as collecting third parties' names or e-mail addresses, whether by electromagnetic means or not, sending e-mails, creating accounts by mechanical means, or creating false accounts.<br />
                  16) Actions which attempt to loan, exchange, transfer, change ownership of, sell, pawn, offer as collateral, or otherwise offer the use of all or part of a PlayMining ID and or Game data to a third party.<br />
                  17) Possessing multiple PlayMining IDs. However, this shall not apply in the event that the Operator's permission has been obtained in advance.<br />
                  18) Use of this game by minors. The age of majority shall conform to the laws of the country in which the User resides. However, this shall not apply in the event that the Operator has granted permission in advance, provided that such permission does not conflict with the laws of the country in which the User resides.<br />
                  19) Using the Games for the purpose of abusing or interfering with the Operator's network or services.<br />
                  20) Actions which circumvent, disable, or otherwise interfere with the technological measures and or security related features implemented by the Operator including functions which prevent or restrict the use and or copying of information and or data or enforce limitations on the use of the Game or the data contained therein or attempt to do so.<br />
                  21) Any act of systematically obtaining data or other content from a game in order to collect, compile, create a database or a directory, directly or indirectly, without written permission from the Operator.<br />
                  22) Use of data mining, scraping, robots, or similar data gathering or extraction methods.<br />
                  23) Uploading or transmitting (or attempting to upload or transmit) any viruses, Trojan horses, malware, corrupted data, or other harmful files that interfere with or alter, hinder, or destroy the uninterrupted use of the Game or the use, features, functions, or maintenance of the Game.<br />
                  24) Develop or use any application or software that is not intended by the Operator to operate the Game.<br />
                  25) Analyze the Game by decompiling, disassembling, reverse engineering, etc.<br />
                  26) Creating data or executable programs that imitate data or functions in the Game.<br />
                  27) Redistributing, reselling, lending, or resale of the Game, in whole or in part, by means of reproduction, etc.<br />
                  28) Prepare, instigate, aid, assist, encourage, or propagate any of the acts set forth above (including omission).<br />
                  29) Any other acts that violate the provisions of these Terms of Use, etc. or laws and regulations, etc.<br />
                  30) Any other acts deemed to be similar to or potentially in conflict with the above-mentioned acts.<br />
                  31) Any other acts that the Operator deems inappropriate.<br />
                  <br />
                  <div className="terms-title">17. Modification, Interruption and Termination of the Game</div>
                  The Operator reserves the right, in its sole discretion, to modify, suspend, or discontinue the Service (or any part thereof) temporarily or permanently, at any time and for any reason, with or without notice to the User. The User agrees that the Operator shall not be liable to the User for any changes to the Service, and that the Operator shall have no obligation to update any information on the Service.<br />
                  The Operator may periodically or irregularly perform maintenance and servicing of equipment, facilities, software, hardware, etc., in the event that such equipment, facilities, software, hardware, etc., malfunctions or maintenance is required in connection with the Service. The User understands and agrees that the Operator shall not be liable for any loss, damage, or inconvenience caused by the User's inability to access or use the Service during the suspension or interruption of the Service.
                  Except in the event of suspension or termination of the Service due to an emergency or unforeseen circumstances beyond the Operator's control, the Operator shall notify the User in advance in a manner the Operator deems appropriate.<br />
                  <br />
                  <div className="terms-title">18. Taxes and Fees</div>
                  The User shall be solely responsible to pay for any and all currency conversion charges, third party fees, sales, use, value-added, personal property or other charges, tax, duty or levy of any kind imposed by different jurisdictions, including interest and penalties thereon, whether imposed now or hereinafter by any governmental entity fees that the User incurs in connection with, or by reason of the User’s use of, the Service.<br />
                  <br />
                  <div className="terms-title">19. Warranties and Disclaimers</div>
                  The Game shall be provided in its present state and the User holds responsibility for the use of the Game. The Operator assumes no liability or responsibility for, explicit or inexplicit product quality, and or suitability for a specific stated or unstated goal, of the Game, including but not limited to the following.<br />
                  1) Stable operation of the Game.<br />
                  2) Absence of bugs or other defects in the Game.<br />
                  3) Reliability or completeness of the Game.<br />
                  4) The ability to use the Game on specific equipment.<br />
                  5) That the Game will not adversely affect a specific type of equipment when used.<br />
                  6) Reliability, effectiveness, and or inaccuracies of any information contained in or obtained through the Game by the User.<br />
                  7) The Game Data to be fully retained.<br />
                  8) The Game does not partially or entirely infringe upon intellectual property rights, etc., of a third party.<br />
                  <br />
                  <div className="terms-title">20. Indemnity</div>
                  When the User causes any damage to the Operator arising out of (A) the User’s use of the Game, (B) violation of these Terms of Use, (C) any other cause attributable to the User, the User agrees to indemnify and hold the Operator (to include its agents, subsidiaries, directors, board members, employees, and business partners) harmless from and against any and all disputes, demands, liabilities, damages, losses and expenses claims by a third party.<br />
                  <br />
                  <div className="terms-title">21. Limitation of Liability</div>
                  Excluding the event in which the Operator is found to be in gross negligence or at fault, the Operator shall not be liable for any or all damages (including indirect, derivative, consequential, punitive, statutory, or special damages, regardless of whether based on lost profits, lost sales, loss or alteration of data, loss or elimination of In-Game Currency, or any other cause of damages including but not limited to breach of contract, illegal activities, indemnity, or breach of warranty) suffered by the User. This is regardless of the Operator being advised of the possibility prior to the incident.<br /><br />
                  With respect to the Service and the Game, the Operator shall not guarantee nor liable for (1) the accuracy of the contents or content, (2) access to the Game by users, (3) the server environment, (4) unauthorized access to personal information or information pertaining to personal property and any associated unauthorized use of such information, (5) communication failures or similar events in the Game, (6) viruses or cyber attacks, (7) errors in the contents and accompanying materials, (8) any other reasons not attributable to the Operator under socially accepted conventions, and the Operator shall not be liable for any damages (including, but not limited to, damages incurred by users) arising from such reasons.<br />
                  <br />
                  <div className="terms-title">22. Amendment of Terms of Use</div>
                  The Operator reserves the right to change, modify, add or remove portions of the Terms of Use and Privacy Policy at any time. In the event that the Operator changes, modifies, adds, or deletes the Terms of Use or Privacy Policy, the Operator may give notice thereof through the Service, the Game's official website, or within the Game, or by other means designated by the Operator. The modified Terms of Use and Privacy Policy shall become effective immediately unless otherwise specified in such notice. The User's continued access to and use of the Service after such notice shall constitute acceptance of the modified Terms of Use and Privacy Policy. If the User does not agree to the modified Terms of Use and Privacy Policy, he/she must immediately cease accessing or using the Service.<br />
                  <br />
                  <div className="terms-title">23. Governing Law and Dispute Resolution</div>
                  The Terms of Use set forth herein shall be governed by and construed in accordance with the laws of the Republic of Singapore. Any dispute, action, claim or cause of action arising out of or relating to these Terms of Use or the Service, including any question as to its existence, validity or termination, shall be referred to arbitration administered by the Singapore International Arbitration Center ("SIAC") and shall be finally determined by a single arbitrator ("Arbitrator") to be appointed by mutual agreement of the parties in accordance with SIAC's Rules of Arbitration ("SIAC Rules"), as amended or revised from time to time. In the event the parties are unable to agree on an arbitrator, the arbitrator shall be appointed by the Director of SIAC in accordance with the SIAC Rules. The venue and venue of the arbitration shall be Singapore, the language of the arbitration shall be English, and the costs of the arbitrator shall be borne equally by the parties. However, the arbitrator may require the parties to bear such costs in any other manner that the arbitrator determines is necessary for this arbitration clause to be enforceable under applicable law.<br />
                  <br />
                  General Provisions<br />
                  (1) Cumulative rights and remedies: Unless otherwise provided under these Terms of Use, the provisions of these Terms of Use and the Operator’s rights and remedies under these Terms of Use are cumulative and are without prejudice and in addition to any rights or remedies the Operator may have in law or in equity, and no exercise by the Operator of any one right or remedy under these Terms of Use, or at law or in equity, shall (save to the extent, if any, provided expressly in these Terms of Use or at law or in equity) operate so as to hinder or prevent our exercise of any other such right or remedy as at law or in equity.<br />
                  (2) No waiver: The failure of the Operator to enforce any right or provision in these Terms of Use shall not constitute a waiver of such right or provision unless acknowledged and agreed to by the Operator in writing.<br />
                  (3) Severability: If any provision of these Terms of Use is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law. This shall, without limitation, also apply to the applicable law and jurisdiction as stipulated in these Terms of Use.<br />
                  (4) Rights of third parties: A person or entity who is not a party to these Terms of Use shall have no right under the Contracts (Rights of Third Parties) Act, Chapter 53B of Singapore or any similar legislation in any jurisdiction to enforce any term of these Terms of Use. For the avoidance of doubt, nothing in this Subsection shall affect the rights of any permitted assignee or transferee of these Terms of Use.<br />
                  (5) Injunctive relief: The Operator may seek immediate injunctive relief if the Operator determines in good faith that a breach or non-performance is such that a temporary restraining order or other immediate injunctive relief is the only appropriate or adequate remedy.<br />
                  (6) Correction of errors: Any typographical, clerical or other error or omission in any acceptance, invoice or other document on the Operator’s part shall be subject to correction without any liability on the Operator’s part.<br />
                  (7) Entire agreement: These Terms of Use shall constitute the entire agreement between the parties relating to the subject matter of these Terms of Use, and supersedes and replaces in full all prior understandings, communications and agreements between the parties in respect of that subject matter.<br />
                  (8) Assignment: The Operator may assign any or all of its rights and obligations to any third party at any time.<br />
                  (9) No partnership: No joint venture, partnership, employment, or agency relationship exists between the User, the Operator or any third party provider as a result of these Terms of Use or use of the Services.<br />
                  (10) Force Majeure: The Operator shall not be liable for non-performance, error, interruption or delay in the performance of the Operator’s obligations under these Terms of Use (or any part thereof) or for any inaccuracy, unreliability or unsuitability of the Services’ contents if this is due, in whole or in part, directly or indirectly to an event or failure which is beyond the Operator’s reasonable control.<br />
                  (11) Termination: The User hereby agrees that the Operator is entitled to terminate these Terms of Use immediately in the event that the User is found to be in breach of any of these terms stipulated in these Terms of Use. For the avoidance of doubt, the Operator is not required to compensate, reimburse or cover any cost incurred by the User in accessing PlayMining NFT or using the Service due to the termination of these Terms of Use.<br />
                  <br />
                  <div className="terms-title">24. Reference of NFTs</div>
                  Users may refer to NFTs purchased on PlayMining NFT in the Game in a manner specified by the Operator.<br />
                  The purchase and sale of NFTs and other detailed terms and conditions shall be governed by the Terms of Use of PlayMining NFT, which shall be set forth separately.
                  {/* Revision History<br />
                  Enacted on February 7, 2022 */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

TermsPage.propTypes = propTypes

export default TermsPage

export const pageQuery = graphql`
  query TermsEnQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`